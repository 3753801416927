import { tabsAnatomy } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/theme';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(tabsAnatomy.keys);
const variantLineTop = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    tablist: {
      borderTop: '2px solid',
      borderColor: 'inherit',
    },
    tab: {
      backgroundColor: 'white',
      fontWeight: 'semibold',
      borderTop: '2px solid',
      borderColor: 'gray.100',
      marginTop: '-2px',
      _selected: {
        color: `${c}.700`,
        borderColor: `${c}.500`,
      },
    },
  };
});
const Tabs: ComponentStyleConfig = {
  variants: {
    lineTop: variantLineTop,
  },
};

export default Tabs;
