import moment from 'moment-timezone';
import { envConfig } from '../config/envConfig';

export const GOOGLE_API_KEY = envConfig.GOOGLE_API_KEY ?? '';

// max and min distance value for route
export const METRIC_TO_IMPERIAL_FACTOR = 0.621371;
export const IMPERIAL_TO_METRIC_FACTOR = 1.60934;
export const MAX_DISTANCE = 2000;
export const MIN_DISTANCE = 60;
export const MAX_ROUTE_COUNT = 30;

export const QS_RESET_PASSWORD_TOKEN_KEY = 'sptoken';

export const MID_GREY_COLOR = '#d3d3d3';
export const OFF_WHITE_COLOR = '#f6f5f4';
export const MID_BLUE_COLOR = '#658dc5';
export const LIGHT_BLUE_COLOR = '#53c1f2';
export const YELLOW_COLOR = '#e4d491';
export const PRIMARY_GREEN = '#00c853';

// Generated with https://mokole.com/palette.html
export const ROUTES_COLORS = [
  '#D69E2E',
  '#B83280',
  '#2B6CB0',
  '#00A3C4',
  '#2C7A7B',
  '#00A042',
  '#DD6B20',
  '#4FD1C5',
  '#B794F4',
  '#2D3748',
  '#285E61',
  '#63B3ED',
  '#F6AD55',
  '#E53E3E',
  '#805AD5',
  '#7E8590',
  '#FC8181',
  '#B7791F',
  '#C05621',
  '#319795',
  '#00B5D8',
  '#6B46C1',
  '#00C853',
  '#3182CE',
  '#F6E05E',
  '#56586B',
  '#D53F8C',
  '#0987A0',
  '#F687B3',
  '#C53030',
];

export const FORWARD_ARROW = 'M-20 20 0 6 0 6 20 20';
export const WHITE_TRIANGLE = 'M-10 18 0 8.5 0 8.5 10 18 10 18 -10 18';

export const TIME_ZONE_OPTIONS = moment.tz
  .names()
  .map((tzName) => ({ value: tzName, label: tzName.replace('_', ' ') }));

export const MAX_SITE_NAME_LENGTH = 36;
