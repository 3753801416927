import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import RedLight from '../../svg/red-light.svg?react';

export default function PublicViewError() {
  return (
    <Box width="full" p={8}>
      <Flex justify="flex-start" mb={{ base: 8, md: 24 }}>
        <Box w="124px" h="27px">
          <MoovenLogo />
        </Box>
      </Flex>
      <Flex flexDirection="column" justify="center" align="center">
        <Box
          w={{ base: '390px', md: '508px' }}
          h={{ base: '393px', md: '493px' }}
        >
          <RedLight />
        </Box>
        <Heading
          as="h3"
          mb={{ base: 14, md: 36 }}
          fontWeight={700}
          size="3xl"
          width={{ base: '380px', md: '856px' }}
          textAlign="center"
        >
          <FormattedMessage
            defaultMessage="Sorry, we couldn't find what you're looking for"
            id="D8Haos"
            description="Public View Error Messages"
          />
        </Heading>
        <Text
          fontSize="lg"
          fontWeight={400}
          mb={8}
          color="gray.700"
          width="360px"
          textAlign="center"
        >
          <FormattedMessage
            defaultMessage="Mooven provides worksite traffic insights to help keep communities mooving."
            id="h7KxcM"
            description="Public View Error Messages"
          />
        </Text>
        <Box w="124px" h="27px">
          <MoovenLogo />
        </Box>
      </Flex>
    </Box>
  );
}
