import { defaultTheme } from 'design-system/theme';
import { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

export default function ConfiguredChakraProvider({
  children,
}: {
  children?: ReactNode;
}) {
  return <ChakraProvider theme={defaultTheme}>{children}</ChakraProvider>;
}
