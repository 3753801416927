export interface EnvConfig {
  API_URL: string;
  SPLIT_CLIENT_API_KEY: string;
  RAYGUN_API_KEY: string;
  INTERCOM_APP: string;
  APP_VERSION: string;
  GOOGLE_API_KEY: string;
  LIVE_DATA_URL: string;
  ADMIN_URL: string;
}

const getAdminURL = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost') || hostname.includes('app.dev.')) {
    return 'https://admin.dev.mooven.com';
  }
  return 'https://admin.prod.mooven.com';
};

const getApiURL = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost') || hostname.match(/\d+\.\d+\.\d+\.\d/)) {
    return '';
  }
  const apiPrefix = 'https://api';
  return apiPrefix.concat('.', hostname);
};

export const getEnvironment = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost') || hostname.match(/\d+\.\d+\.\d+\.\d/)) {
    return 'local';
  }
  if (hostname.includes('dev')) {
    return 'test';
  }
  return 'production';
};

export const getPublicViewURL = () => {
  switch (getEnvironment()) {
    case 'test':
      return 'https://live.dev.mooven.com';
    case 'production':
      return 'https://live.mooven.com';
    default:
      return 'https://live.dev.mooven.com';
  }
};

const getRaygunApiKey = () => {
  switch (getEnvironment()) {
    case 'test':
      return '1FVlJ8Kv0m6KZOyM54iiNg';
    case 'production':
      return 'gh9fXlZpBjVwxvTgb95emg';
    default:
      return '';
  }
};

const liveDataUrlFallback =
  getEnvironment() === 'production'
    ? 'https://live.mooven.com'
    : 'https://live.dev.mooven.com';

export const envConfig: EnvConfig = {
  ADMIN_URL: getAdminURL(),
  API_URL: getApiURL(),
  RAYGUN_API_KEY: getRaygunApiKey(),
  INTERCOM_APP: getEnvironment() === 'production' ? 'rkrctuz0' : 'vucj8rl7',
  LIVE_DATA_URL: liveDataUrlFallback,
  SPLIT_CLIENT_API_KEY: process.env.SPLIT_CLIENT_API_KEY || '',
  APP_VERSION: process.env.APP_VERSION || 'local',
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY || '',
};
