import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  PublicRoute,
  PublicViewChartType,
  ViewChartData,
  ViewConfig,
} from './types';
import useTimePeriod from './useTimePeriod';
import useViewConfigData from './useViewConfigData';
import useViewChartsData from './useViewChartData';

type RouteId = PublicRoute['routeId'];

interface PublicRouteWithSelect extends PublicRoute {
  selected: boolean;
}

interface PublicViewContext {
  viewName: string;
  viewTimePeriod: string;
  siteLocation?: google.maps.LatLngLiteral;
  routes: PublicRouteWithSelect[];
  toggleRouteVisibility: (id: RouteId) => void;
  selectAllRoutes: () => void;
  deselectAllRoutes: () => void;
  isLoadingConfig: boolean;
  isLoadingCharts: boolean;
  configError: any;
  viewConfig: ViewConfig | undefined;
  chartTypes: PublicViewChartType[];
  chartsData: Record<string, ViewChartData>;
}

export const PublicViewCtx = createContext<PublicViewContext | null>(null);

interface Props {
  children?: ReactNode;
}

export type PathParams = 'linkId';

export function PublicViewProvider({ children }: Props) {
  const params = useParams<PathParams>();
  const { linkId } = params;
  const {
    data: viewConfig,
    error: configError,
    isLoading: isLoadingConfig,
  } = useViewConfigData(linkId);
  const { data: viewCharts, isLoading: isLoadingCharts } = useViewChartsData(
    linkId,
    viewConfig?.chartTypes ?? []
  );
  const chartsData: Record<string, ViewChartData> = useMemo(
    () =>
      (viewCharts ?? []).reduce((prev, curr) => {
        if (curr) {
          return { ...prev, [curr.insightType]: curr };
        }
        return { ...prev };
      }, {}),
    [viewCharts]
  );

  const [routes, setRoutes] = useState<PublicRouteWithSelect[]>([]);
  const timePeriod = useTimePeriod(
    viewConfig?.timePeriodType || 'TIME_PERIOD_LAST_24_HOURS',
    viewConfig?.startDate ?? 0,
    viewConfig?.endDate ?? 0
  );

  const toggleRouteVisibility = useCallback(
    (id: RouteId) => {
      setRoutes((current) =>
        current.map((route) =>
          id === route.routeId ? { ...route, selected: !route.selected } : route
        )
      );
    },
    [setRoutes]
  );

  const selectAllRoutes = useCallback(
    (selected: boolean = true) => {
      const sortedRoutes = (viewConfig?.routes || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((route) => ({ ...route, selected }));
      setRoutes(sortedRoutes);
    },
    [viewConfig?.routes, setRoutes]
  );

  const deselectAllRoutes = useCallback(() => {
    selectAllRoutes(false);
  }, [selectAllRoutes]);

  useEffect(() => {
    if (viewConfig?.routes) {
      selectAllRoutes();
    }
  }, [viewConfig?.routes, selectAllRoutes]);

  const value: PublicViewContext = useMemo(
    () => ({
      viewName: viewConfig?.viewName || '',
      viewTimePeriod: timePeriod,
      siteLocation: viewConfig?.siteLocation,
      routes,
      toggleRouteVisibility,
      selectAllRoutes,
      deselectAllRoutes,
      configError,
      isLoadingConfig,
      isLoadingCharts,
      viewConfig,
      chartTypes: viewConfig?.chartTypes ?? [],
      chartsData,
    }),
    [
      viewConfig,
      routes,
      toggleRouteVisibility,
      selectAllRoutes,
      deselectAllRoutes,
      configError,
      isLoadingConfig,
      isLoadingCharts,
      timePeriod,
      chartsData,
    ]
  );

  if (!linkId) {
    window.location.href = 'https://mooven.com';
    return null;
  }

  return (
    <PublicViewCtx.Provider value={value}>{children}</PublicViewCtx.Provider>
  );
}

export function usePublicView() {
  const ctx = useContext(PublicViewCtx);

  if (!ctx) {
    throw new Error(
      'Can only consume usePublicView context from within a provider'
    );
  }

  return ctx;
}
