import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { keyframes } from '@chakra-ui/system';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const Spinner: ComponentStyleConfig = {
  variants: {
    mooven: {
      color: 'green.700',
      animation: `${spin} 1s linear infinite`,
    },
  },
};

export default Spinner;
