import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  CheckboxGroup,
  Collapse,
  Flex,
  Heading,
  useBreakpoint,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'design-system/molecules/checkbox';
import { colorsSortedByKey } from '../../utils/routes';
import { usePublicView } from './usePublicView';

export default function PublicRouteSelector() {
  const { routes, selectAllRoutes, deselectAllRoutes, toggleRouteVisibility } =
    usePublicView();
  const size = useBreakpoint();
  const { isOpen, onToggle, onOpen } = useDisclosure({ defaultIsOpen: true });
  const routesColors = colorsSortedByKey(routes, 'routeId');
  const areAllSelected = routes.every((route) => route.selected);
  const mobileToggle = useCallback(() => {
    if (size === 'sm') {
      onToggle();
    }
  }, [size, onToggle]);

  useEffect(() => {
    if (size !== 'sm' && !isOpen) {
      onOpen();
    }
  }, [size, isOpen, onOpen]);

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        cursor={{ sm: 'pointer', md: 'auto' }}
        onClick={mobileToggle}
      >
        <Heading as="h3" size="md" mb={3}>
          <FormattedMessage
            defaultMessage="Routes"
            id="tEpK2o"
            description="Public view routes selector title"
          />
        </Heading>
        <ChevronDownIcon
          visibility={{ sm: 'visible', md: 'hidden' }}
          boxSize={6}
          transition="0.2s transform ease-in-out"
          transform={isOpen ? 'rotate(180deg)' : undefined}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Button
          mb={3}
          size="xs"
          variant="outline"
          onClick={() =>
            areAllSelected ? deselectAllRoutes() : selectAllRoutes()
          }
        >
          {areAllSelected ? (
            <FormattedMessage defaultMessage="Deselect all" id="Spd88H" />
          ) : (
            <FormattedMessage defaultMessage="Select all" id="94Fg25" />
          )}
        </Button>
        <CheckboxGroup>
          <VStack spacing={4} align="start">
            {routes.map(({ routeId, name, selected }) => (
              <Checkbox
                key={routeId}
                checked={selected}
                color={routesColors[routeId]}
                onChange={() => toggleRouteVisibility(routeId)}
              >
                {name}
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      </Collapse>
    </>
  );
}
