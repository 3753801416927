import { ReactNode } from 'react';
import styles from './index.module.scss';

export default function LayoutEmpty({
  image,
  title,
  text,
  children,
}: {
  image?: ReactNode;
  title?: ReactNode;
  text?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <div className={styles.root} data-testid="empty-message">
      <div className={styles.content}>
        {image && <div className={styles.imageContainer}>{image}</div>}
        {title && <h2 className={styles.title}>{title}</h2>}
        {text && <div className={styles.text}>{text}</div>}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}
