import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    th: {
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  },
});
