export default {
  baseStyle: {
    p: 6,
    shadow: 'sm',
    borderRadius: 'lg',
  },
  variants: {
    link: {
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      _hover: {
        shadow: 'lg',
        transform: 'translateY(-0.25rem)',
      },
    },
  },
};
