import useSWR from 'swr';
import { envConfig } from '../../config/envConfig';
import { ViewConfig } from './types';

export interface ViewConfigDataHandler {
  data: ViewConfig;
  error: any;
  isValidating: boolean;
}

export default function useViewConfigData(linkId: string | undefined) {
  const { data, error } = useSWR<ViewConfig>(
    linkId ? `${envConfig.LIVE_DATA_URL}/data/${linkId}/config.json` : null,
    null,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data,
    error,
    isLoading: !error && !data,
  };
}
