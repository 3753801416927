import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import Chart from 'design-system/atoms/chart';
import NoDataForDates from '../../svg/no-data-for-dates.svg?react';

export default function EmptyInsightChart({ title }: { title: string }) {
  return useMemo(
    () => (
      <Chart
        overlayComponent={
          <LayoutEmpty
            image={<NoDataForDates width="298" />}
            title={
              <FormattedMessage
                defaultMessage="Try changing the date range"
                id="Im7Fa+"
                description="Empty chart title for insight charts"
              />
            }
            text={
              <FormattedMessage
                defaultMessage={`
                  There’s no information available for the selected dates.
                `}
                id="UqIV9F"
                description="Empty chart message for insight charts"
              />
            }
          />
        }
        options={{
          title: {
            text: title,
          },
          legend: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
        }}
      />
    ),
    [title]
  );
}
