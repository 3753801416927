const ArrowIcon = ({
  color = '#2D3748',
  direction,
}: {
  color?: string;
  direction: 'up' | 'down';
}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {direction === 'up' && (
      <path
        fill={color}
        d="M6.66645 3.2185V11.3332H5.33312V3.2185L1.75712 6.7945L0.814453 5.85184L5.99979 0.666504L11.1851 5.85184L10.2425 6.7945L6.66645 3.2185Z"
      />
    )}

    {direction === 'down' && (
      <path
        fill={color}
        d="M6.66645 8.78117L10.2425 5.20517L11.1851 6.14784L5.99979 11.3332L0.814453 6.14784L1.75712 5.20517L5.33312 8.78117V0.666504H6.66645V8.78117Z"
      />
    )}
  </svg>
);

export default ArrowIcon;
