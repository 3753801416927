import { ComponentStyleConfig } from '@chakra-ui/theme';

const Drawer: ComponentStyleConfig = {
  parts: ['dialog, dialogContainer'],
  variants: {
    nonModalDrawer: {
      dialog: {
        pointerEvents: 'auto',
        boxShadow: 'none',
        bg: 'transparent',
        overflowY: 'auto',
        display: 'block',
        '&::-webkit-scrollbar': { display: 'none' },
        mt: '10px',
        mr: { base: '0', md: '10px' },
        ml: { base: '0', md: '10px' },
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
};

export default Drawer;
