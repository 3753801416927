import { ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { SWRDevTools } from 'swr-devtools';
import { standardFetch } from '../utils/fetch';

export function ConfiguredSWRWrapper({ children }: { children?: ReactNode }) {
  return (
    <SWRDevTools>
      <SWRConfig
        value={{
          fetcher: standardFetch,
          dedupingInterval: 500,
          focusThrottleInterval: 500,
        }}
      >
        {children}
      </SWRConfig>
    </SWRDevTools>
  );
}
