import { Flex, Text, Box, useCheckbox } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { runIfFn } from '@chakra-ui/utils';
import { ReactNode } from 'react';

interface CheckboxProps {
  checked: boolean;
  checkboxSize?: string;
  labelSize?: string;
  color: string;
  children: ReactNode;
  onChange?: (selected: boolean) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  badge?: ReactNode;
}

export default function Checkbox({
  checked,
  color,
  children,
  checkboxSize = 'lg',
  labelSize = 'xs',
  onChange,
  onMouseEnter,
  onMouseLeave,
  badge,
}: CheckboxProps) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox({
      isChecked: checked,
      onChange: () => runIfFn(onChange, !checked),
    });
  const checkboxDimensions = getCheckboxSize(checkboxSize);
  return (
    <Flex
      data-group
      as="label"
      justifyContent="flex-start"
      alignItems="center"
      minWidth="0" // this is needed to prevent the label from overflowing
      gap={2}
      cursor="pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      _hover={
        state.isChecked
          ? {
              filter: 'brightness(85%)',
            }
          : {}
      }
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        {...checkboxDimensions.checkbox}
        {...getCheckboxProps()}
        flex="0"
        minWidth={checkboxDimensions.checkbox.width}
        position="relative"
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor={color}
        _checked={{
          backgroundColor: `${color}`,
          borderColor: `${color}`,
        }}
        rounded="sm"
      >
        {state.isChecked && (
          <CheckIcon {...checkboxDimensions.icon} color="white" />
        )}
        {badge && (
          <Box
            display="flex"
            position="absolute"
            top="-10px"
            right="-10px"
            pointerEvents="none"
          >
            {badge}
          </Box>
        )}
      </Flex>
      <Text
        mb={0}
        fontSize={labelSize}
        _groupHover={{
          color: 'gray.500',
        }}
        color="gray.700"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        {...getLabelProps()}
      >
        {children}
      </Text>
    </Flex>
  );
}

function getCheckboxSize(size: string) {
  switch (size) {
    case 'md':
      return {
        checkbox: { width: 4, height: 4 },
        icon: { width: 3, height: 3 },
      };
    case 'sm':
      return {
        checkbox: { width: 3, height: 3 },
        icon: { width: 2, height: 2 },
      };
    case 'lg':
    default:
      return {
        checkbox: { width: 5, height: 5 },
        icon: { width: 3, height: 3 },
      };
  }
}
