import rg4js from 'raygun4js';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation, Location, useParams } from 'react-router-dom';
import useCurrentUserData from '../../data/useCurrentUserData';
import { User } from '../../types/user';
import { getEmailDomain } from '../../utils/email';
import useInsightData from '../../data/useInsightData';

declare global {
  interface Window {
    analytics: any;
  }
}

function isMoovenEmail(email?: string) {
  return email?.endsWith('@mooven.com') || email?.endsWith('@mooven.io');
}

interface TrackMetadata {
  referrer: string;
  [key: string]: any;
}

export interface Analytics {
  track(event: string, data: TrackMetadata): void;
  pageView(): void;
  identifyUser(user: User): void;
  groupUser(userId: string, userData?: any): void;
  setSessionTag(tag: string, value: any): void;
}

export const AnalyticsContext = createContext<Analytics>({
  track: (...args) => {
    if (window.analytics?.track) {
      window.analytics.track(...args);
    }
  },
  pageView: () => {
    if (window.analytics?.page) {
      window.analytics.page();
    }
  },
  identifyUser(user?) {
    // identify mooven+qa1@mooven.com as anonymous as we want to exclude it in analytics systems
    if (!user) {
      const anonymousUserId = `anonymous_user`;
      // identify on raygun:
      rg4js('setUser', {
        identifier: anonymousUserId,
        isAnonymous: true,
      });
      // identify on segment (segment already assigns anonymous id):
      if (window.analytics?.identify) {
        window.analytics.identify({
          isAnonymous: true,
        });
      }
      // identify on clarity
      if (window.clarity) {
        window.clarity('identify', anonymousUserId);
      }
    } else {
      // identify on raygun:
      rg4js('setUser', {
        identifier: `${user.userId}`,
        isAnonymous: user.email === 'mooven+qa1@mooven.com',
        email: user.email,
        firstName: user.firstName,
        fullName: `${user.firstName} ${user.lastName}`,
      });
      // identify on segment:
      if (window.analytics?.identify) {
        window.analytics.identify((user.userId || 0).toString(), {
          isAnonymous: false,
          email: user.email,
          domain: getEmailDomain(user.email),
          phone: user.phone,
          externalId: user.externalId,
          firstName: user.firstName,
          lastName: user.lastName,
          projects: user.projects,
          role: localStorage.getItem('currentRole'),
          mooven_internal: `${isMoovenEmail(user.email)}`,
        });
      }
      // identify on clarity
      if (window.clarity) {
        window.clarity('identify', `${user.userId}`);
        if (user.email === 'mooven+qa1@mooven.com') {
          window.clarity('set', 'mooven_test', true);
        }
        if (!isMoovenEmail(user.email)) {
          window.clarity('upgrade', 'customer_session');
        }
      }
    }
  },
  groupUser(groupId, groupData) {
    // set group data on segment
    if (window.analytics?.group) {
      window.analytics.group(groupId, groupData);
    }
    // flag mooven group in clarity
    if (window.clarity) {
      window.clarity(
        'set',
        'mooven_internal',
        `${groupId === 'mooven_internal'}`
      );
    }
  },
  setSessionTag: (segmentName: string, segmentValue: any) => {
    if (window.clarity) {
      window.clarity('set', segmentName, `${segmentValue}`);
    }
  },
});

interface ProviderProps {
  children?: React.ReactNode;
}

export function AnalyticsPageViewTracker() {
  const location = useLocation();
  const { pageView } = useContext(AnalyticsContext);
  const prevLocationRef = useRef<Location>();
  useEffect(() => {
    if (
      prevLocationRef.current?.pathname !== location.pathname ||
      prevLocationRef.current?.search !== location.search
    ) {
      pageView();
      rg4js('trackEvent', {
        type: 'pageView',
        path: location.pathname,
      });
    }
    prevLocationRef.current = location;
  }, [location, pageView]);
  return null;
}

export function UserAnalyticsProvider({ children }: ProviderProps) {
  const analyticsContextValue = useContext(AnalyticsContext);
  const { track } = analyticsContextValue;
  const location = useLocation();
  const params = useParams();
  const insightData = useInsightData(params?.insightId);
  const enhancedAnalyticsContextValue = useMemo(
    () => ({
      ...analyticsContextValue,
      track: (event: string, data: TrackMetadata) => {
        const urlSiteId = new URLSearchParams(location.search).get('siteId');
        const siteContext = urlSiteId ? { siteId: urlSiteId } : {};
        const insightId = params?.insightId;
        const insightName = insightData.data?.name ?? '';
        const projectContext = insightId ? { insightId, insightName } : {};
        track(event, {
          ...projectContext,
          ...siteContext,
          ...data,
        });
      },
    }),
    [
      location.search,
      params?.insightId,
      insightData.data?.name,
      analyticsContextValue,
      track,
    ]
  );

  return (
    <AnalyticsContext.Provider value={enhancedAnalyticsContextValue}>
      <AnalyticsUserIdentityTracker />
      {children}
    </AnalyticsContext.Provider>
  );
}

function AnalyticsUserIdentityTracker() {
  const { groupUser, identifyUser } = useContext(AnalyticsContext);
  const { data: currentUser } = useCurrentUserData();
  const identifiedUserIdRef = useRef<number>();

  useEffect(() => {
    if (identifiedUserIdRef.current !== currentUser?.userId) {
      identifiedUserIdRef.current = currentUser?.userId;
      if (currentUser) {
        identifyUser(currentUser);
      }

      if (currentUser) {
        // Group mooven internal staff into a special bucket
        if (isMoovenEmail(currentUser.email)) {
          groupUser('mooven_internal');
        } else if (currentUser.customerData) {
          currentUser.customerData.forEach((customer) => {
            if (customer.externalId !== undefined) {
              groupUser(customer.externalId, {
                name: customer.customerName,
              });
            }
          });
        } else {
          (currentUser.customers ?? []).forEach((customer) => {
            groupUser(customer);
          });
        }
      }
    }
  }, [currentUser, groupUser, identifyUser]);
  return null;
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}
