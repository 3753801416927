import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const Modal: ComponentMultiStyleConfig = {
  parts: ['header'],
  baseStyle: {
    header: {
      fontWeight: 700,
    },
    dialog: {
      borderRadius: '32px',
    },
  },
};

export default Modal;
