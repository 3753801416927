import flatMap from 'lodash/flatMap';
import { useEffect, useRef } from 'react';
import { MAP_DEFAULT_CENTER } from '../constants/map';
import { LatLng } from '../types/site';
import { Maybe } from '../types/utils';
import {
  getBoundsWithMinDiameter,
  parseWkt,
  SwNeBounds,
} from '../utils/routes';

export interface WithWkt {
  wkt: string;
}

interface WithLocation {
  location: LatLng;
}

interface Params {
  mapInstance: Maybe<google.maps.Map>;
  routes?: WithWkt[];
  workAreas?: WithWkt[];
  sources?: WithLocation[];
  defaultCenter?: google.maps.LatLngLiteral;
}

export default function useMapBounds({
  mapInstance,
  routes = [],
  workAreas = [],
  sources = [],
  defaultCenter = MAP_DEFAULT_CENTER,
}: Params) {
  const boundsRef = useRef<SwNeBounds>();
  useEffect(() => {
    if (mapInstance) {
      const routeLatLngs = flatMap([...routes, ...workAreas], ({ wkt }) =>
        parseWkt(wkt)
      );
      const sourceLatLngs = sources.map(({ location }) => ({
        lat: location.lat!,
        lng: location.lng!,
      }));
      const allLatLngs = [...routeLatLngs, ...sourceLatLngs];
      const newBounds = getBoundsWithMinDiameter(
        allLatLngs.length > 0 ? allLatLngs : [defaultCenter]
      );
      if (
        !boundsRef.current ||
        boundsRef.current.sw.lat !== newBounds.sw.lat ||
        boundsRef.current.sw.lng !== newBounds.sw.lng
      ) {
        boundsRef.current = newBounds;
        mapInstance.fitBounds(
          new google.maps.LatLngBounds(newBounds.sw, newBounds.ne)
        );
      }
    }
  }, [routes, mapInstance, defaultCenter, sources, workAreas]);
}
