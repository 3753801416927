import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

export default defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'xl',
    },
    title: {
      lineHeight: 5,
      fontSize: 'sm',
    },
    description: {
      fontSize: 'xs',
      lineHeight: 4,
      // inline-block to correct line height (inline will be limited by parent line height)
      display: 'inline-block',
      a: {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
  },
  variants: {
    subtle: definePartsStyle((props) => {
      const { colorScheme: c } = props;
      return {
        container: {
          background: `${c === 'orange' ? 'yellow' : c}.100`,
        },
        icon: {
          color: `${c}.500`,
        },
      };
    }),
  },
});
