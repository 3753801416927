import Color from 'color';
import { ROUTES_COLORS } from '../constants/clientConstants';

/**
 * our designer has specified our lines to be 50% opacity,
 * as we want to reuse the same chart we are preventing svg => pdf opacity issues
 * by returning the hex version of line @ x opacity
 */
export function getCompareColor(color: string) {
  return Color(color).alpha(0.5).string();
}

export function getColorBySiteId(siteId: number) {
  return ROUTES_COLORS[siteId % ROUTES_COLORS.length];
}

export function getLabelColorBySiteId(siteId: number) {
  return getColorBySiteId(siteId);
}

export function getLabelFontColorBySiteId(siteId: number) {
  return Color(getColorBySiteId(siteId)).contrast(Color('#ffffff')) > 3
    ? '#ffffff'
    : '#000000';
}
