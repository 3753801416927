import { memo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useIntl } from 'react-intl';
import Highcharts, {
  AnnotationsOptions,
  Axis,
  AxisTypeValue,
  AxisSetExtremesEventObject,
  SeriesLineOptions,
  SeriesOptionsType,
  TooltipFormatterContextObject,
} from 'highcharts';
import moment from 'moment-timezone';
import { Box, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import Chart from 'design-system/atoms/chart';
import {
  afterChartRender,
  InsightChartConfig,
  InsightChartSeries,
} from '../../utils/insightCharts';
import Tooltip from './Tooltip';
import {
  formatKpiLineToHighcharts,
  makeAnnotationUnclickable,
} from '../../utils/highcharts';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export interface InsightChartProps {
  timeZone: string;
  chartConfig: InsightChartConfig;
  chartSeries: InsightChartSeries[];
  startTime: number;
  endTime: number;
  compareDate?: number;
  annotations?: {
    startDate?: number;
    endDate?: number;
    title?: string;
    type?: string;
  }[];
  shouldAnimate?: boolean;
  showContextMenu?: boolean;
  exportGraphCSV?: () => void;
  exportAnnotations?: () => void;
  customChartRender?: (chartInstance: Highcharts.Chart) => void;
  callback?: (chartInstance: Highcharts.Chart) => void;
  chartContainerRef?: React.RefObject<HTMLDivElement>;
  actions?: React.ReactNode;
  extraConfig?: React.ReactNode;
  customExportChartRender?: (chartInstance: Highcharts.Chart) => void;
}

export function InsightChartImpl({
  timeZone,
  chartConfig,
  chartSeries,
  startTime,
  endTime,
  compareDate,
  annotations = [],
  showContextMenu = true,
  callback,
  shouldAnimate = true,
  exportGraphCSV,
  exportAnnotations,
  customChartRender,
  chartContainerRef,
  actions,
  extraConfig,
  customExportChartRender,
}: InsightChartProps) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const trackChartEvents = (eventName, eventProps = {}) => {
    track(eventName, {
      ...eventProps,
      referrer: 'Insight Chart',
    });
  };
  const chartAnnotations = annotations
    .filter((a) => a)
    .filter(
      (a) =>
        (a.startDate &&
          a.startDate <= endTime / 1000 &&
          a.endDate &&
          a.endDate >= startTime / 1000) ||
        (!a.endDate &&
          a.startDate &&
          a.startDate >= startTime / 1000 &&
          a.startDate <= endTime / 1000)
    )
    .map(formatAnnotationToHighcharts)
    .concat(
      !chartConfig.kpi
        ? []
        : ([formatKpiLineToHighcharts(chartConfig.kpi)] as any)
    ) as AnnotationsOptions[];
  const responsiveStyles = useBreakpointValue({
    base: {
      titleMargin: 54,
    },
    md: {
      titleMargin: 24,
    },
  });
  const chartOptions = {
    chart: {
      events: {
        render() {
          const chartInstance = this as unknown as Highcharts.Chart;
          afterChartRender(chartInstance);
          if (customChartRender) {
            customChartRender(chartInstance);
          }
        },
      },
      ignoreHiddenSeries: chartSeries.some(
        (series) => series.visible && (series as SeriesLineOptions).data?.length
      ),
      animation: shouldAnimate,
      zooming: {
        type: chartConfig.zoomingType,
      },
    },
    plotOptions: {
      line: {
        states: {
          hover: {
            lineWidthPlus: 0,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: undefined,
      margin: responsiveStyles?.titleMargin,
    },
    yAxis: [
      {
        top: '13.5%',
        height: '86.5%',
        softMin: 0,
        softMax: chartConfig.kpi ? chartConfig.kpi.value * 1.05 : undefined,
        title: {
          text: chartConfig.mainAxis.title,
          reserveSpace: false,
          rotation: 0,
          x: 0,
          y: -32,
          align: 'high' as 'high',
          textAlign: 'left' as 'left',
        },
        tickInterval: chartConfig.mainAxis.interval,
        labels: {
          formatter: (item) =>
            chartConfig.mainAxis.formatValue
              ? chartConfig.mainAxis.formatValue(item.value)
              : item.value,
        },
        plotBands: chartConfig.mainAxis.plotBands,
      },
    ],
    xAxis: {
      crosshair: !chartConfig.disableCrosshair,
      type: 'datetime' as AxisTypeValue,
      softMin: startTime,
      softMax: endTime,
      title: {
        style: {
          fontSize: '12px',
        },
        text: formatMessage(
          {
            defaultMessage: 'Time in {timeZone}',
            id: 'Ez/Pyo',
            description: 'Time based chart x axis label',
          },
          { timeZone }
        ),
      },
      events: {
        // eslint-disable-next-line object-shorthand
        afterSetExtremes: function (
          this: Axis,
          event: AxisSetExtremesEventObject
        ) {
          if (event.trigger === 'zoom') {
            Highcharts.charts.forEach((chart) => {
              // eslint-disable-next-line react/no-this-in-sfc
              if (chart && this.chart !== chart) {
                const isChartZoomed =
                  chart.xAxis[0].getExtremes().userMax !== undefined &&
                  chart.xAxis[0].getExtremes().userMin !== undefined;
                if (
                  event.userMin === undefined &&
                  event.userMax === undefined
                ) {
                  if (isChartZoomed) {
                    chart.zoomOut();
                    // work around to hide the reset zoom button
                    // https://www.highcharts.com/forum/viewtopic.php?t=46196
                    const zoomButtons = document.querySelectorAll(
                      '.highcharts-button.highcharts-reset-zoom'
                    );
                    zoomButtons.forEach((zoomButton) => {
                      zoomButton.setAttribute('style', 'display: none');
                    });
                  }
                } else {
                  chart.xAxis[0].setExtremes(event.min, event.max);
                  chart.showResetZoom();
                }
              }
            });
          }
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: chartSeries as SeriesOptionsType[],
    annotations: chartAnnotations,
    time: {
      timezone: timeZone,
      moment,
    },
    tooltip: {
      enabled: !chartConfig.disableTooltip,
      useHTML: true,
      shared: true,
      animation: shouldAnimate,
      borderRadius: 6,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderColor: '#f3f3f3',
      shadow: false,
      outside: true,
      style: {
        zIndex: 2000,
      },
      formatter() {
        const formatterObject =
          this as unknown as TooltipFormatterContextObject;
        if (!formatterObject.points && !formatterObject.series) {
          return '';
        }
        if (formatterObject.points) {
          formatterObject.points.sort(
            (pointA, pointB) => pointB.y! - pointA.y!
          );
        }
        const tooltipInHtml = ReactDOMServer.renderToStaticMarkup(
          <Tooltip
            timeZone={timeZone}
            points={formatterObject.points || [formatterObject]}
            formatValue={chartConfig.mainAxis.formatValue}
            compareDate={compareDate}
            dataStartTime={startTime}
            formatMessage={formatMessage}
          />
        );
        return tooltipInHtml.toString();
      },
    },
    exporting: {
      enabled: showContextMenu,
      chartOptions: {
        title: {
          text: chartConfig.title,
        },
        legend: {
          enabled: true,
        },
        annotations: chartAnnotations,
        chart: {
          events: {
            render() {
              const chartInstance = this as unknown as Highcharts.Chart;
              if (customExportChartRender) {
                customExportChartRender(chartInstance);
              }
            },
          },
        },
      },
      menuItemDefinitions: {
        exportGraphCSV: {
          text: formatMessage({
            defaultMessage: 'Download CSV',
            id: 's3ZTDJ',
            description: 'Export Graph data as CSV',
          }),
          onclick() {
            if (exportGraphCSV) {
              exportGraphCSV();
            }
          },
        },
        exportAnnotationsCSV: {
          text: formatMessage({
            defaultMessage: 'Export Annotations',
            id: 'bVguRb',
            description: 'Export annotations menu item',
          }),
          onclick() {
            if (exportAnnotations) {
              exportAnnotations();
            }
          },
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ]
            .concat(exportGraphCSV || exportAnnotations ? 'separator' : [])
            .concat(exportGraphCSV ? 'exportGraphCSV' : [])
            .concat(exportAnnotations ? 'exportAnnotationsCSV' : []),
        },
      },
    },
  };
  return (
    <Flex direction="column">
      <Flex
        position="relative"
        direction={{ base: 'column', md: 'row' }}
        alignItems="flex-end"
        justifyContent="space-between"
        px={4}
        pt={4}
      >
        <Flex direction="column">
          <Heading size="md"> {chartConfig.title} </Heading>
          {extraConfig}
        </Flex>
        {actions}
      </Flex>
      <Box ref={chartContainerRef} position="relative">
        <Chart
          eventTracker={trackChartEvents}
          options={chartOptions}
          callback={callback}
        />
      </Box>
    </Flex>
  );
}

export default memo(InsightChartImpl);

function formatAnnotationToHighcharts(annotation: {
  startDate?: number;
  endDate?: number;
  title?: string;
}): AnnotationsOptions | null {
  if (annotation.startDate === undefined) {
    return null;
  }
  const yPosition = 38;
  const startDateMS = annotation.startDate * 1000;
  const endDateMS = (annotation.endDate || 0) * 1000;
  const annotationColor = '#319795';
  const annotationBorderColor = 'rgba(49, 151, 149, 0.3)';
  const annotationBackgroundColor = 'rgba(185, 246, 202, 0.3)';
  const textColor = '#FFFFFF';

  return {
    draggable: '',
    events: {
      add: makeAnnotationUnclickable,
    },
    labelOptions: {
      x: 5,
      y: 0,
      overflow: 'allow',
      allowOverlap: true,
      shape: 'rect',
      align: 'left',
      verticalAlign: 'middle',
      borderRadius: 6,
      backgroundColor: annotationColor,
      borderWidth: 0,
      style: {
        fontSize: '12px',
        color: textColor,
        // The next two props need to reset props from KPI label due to a Highcharts bug
        fontWeight: 'normal',
        textTransform: 'none',
      },
    },
    labels: [
      {
        point: {
          x: startDateMS,
          xAxis: 0,
          y: yPosition,
          yAxis: null,
        },
        text: annotation.title,
      },
    ],
    shapes: [
      {
        type: 'circle',
        r: 4,
        fill: annotationColor,
        strokeWidth: 0,
        // need to use "points" instead of "point", to override KPI label due to a Highcharts bug
        points: [
          {
            x: startDateMS,
            xAxis: 0,
            y: yPosition,
            yAxis: null,
          },
        ],
      },
      {
        type: 'path',
        strokeWidth: 1,
        stroke: annotationBorderColor,
        points: [
          {
            x: startDateMS,
            xAxis: 0,
            y: 0,
            yAxis: null,
          },
          (pointProps) => ({
            x: startDateMS,
            xAxis: 0,
            y: pointProps.chart.yAxis[0].min,
            yAxis: 0,
          }),
        ],
      },
    ].concat(
      endDateMS === 0
        ? []
        : ([
            {
              type: 'path',
              strokeWidth: 1,
              stroke: annotationBorderColor,
              points: [
                {
                  x: endDateMS,
                  xAxis: 0,
                  y: 0,
                },
                (pointProps) => ({
                  x: endDateMS,
                  xAxis: 0,
                  y: pointProps.chart.yAxis[0].min,
                  yAxis: 0,
                }),
              ],
            },
            {
              type: 'path',
              strokeWidth: 0,
              fill: annotationBackgroundColor,
              points: [
                {
                  x: startDateMS,
                  xAxis: 0,
                  y: 0,
                },
                {
                  x: endDateMS,
                  xAxis: 0,
                  y: 0,
                },
                (pointProps) => ({
                  x: endDateMS,
                  xAxis: 0,
                  y: pointProps.chart.yAxis[0].min,
                  yAxis: 0,
                }),
                (pointProps) => ({
                  x: startDateMS,
                  xAxis: 0,
                  y: pointProps.chart.yAxis[0].min,
                  yAxis: 0,
                }),
              ],
            },
          ] as any[])
    ),
  };
}
