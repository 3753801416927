const input = document.createElement('input');

const isValidEmailAddress = (value: string) => {
  input.type = 'email';
  input.value = value;
  return input.checkValidity();
};

export const emailValidator = (emails: string[]) =>
  emails.every(isValidEmailAddress);

export const getEmailDomain = (email: string = '') => {
  const parts = email.split('@');
  if (parts.length === 2) {
    return parts[1];
  }
  return '';
};
