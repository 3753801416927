import useSWR from 'swr';
import { envConfig } from '../../config/envConfig';
import { standardFetch } from '../../utils/fetch';
import { PublicViewChartType, ViewChartData } from './types';

function makeFetcher(
  linkId: string | undefined,
  chartTypes: PublicViewChartType[]
) {
  return async function fetchCharts() {
    const responses = await Promise.all(
      chartTypes.map(async (chartType) => {
        const url = `${envConfig.LIVE_DATA_URL}/data/${linkId}/${chartType}.json`;
        const response = await standardFetch<ViewChartData>(url);
        if (!response) {
          return null;
        }
        return response;
      })
    );

    return responses;
  };
}

export default function useViewChartsData(
  linkId: string | undefined,
  chartTypes: PublicViewChartType[]
) {
  // Use a generic key for SWR as we want to cache & retrieve all
  // chart data for a view in lockstep
  const { data, error } = useSWR(
    linkId && chartTypes.length > 0
      ? `${envConfig.LIVE_DATA_URL}/data/${linkId}/charts.json`
      : null,
    makeFetcher(linkId, chartTypes),
    {
      refreshInterval: 5 * 60 * 1000,
      refreshWhenHidden: true,
    }
  );
  return {
    data,
    error,
    isLoading: !error && !data,
  };
}
