import { Box } from '@chakra-ui/react';
import { GoogleMap, GoogleMapProps, Polygon } from '@react-google-maps/api';
import {
  memo,
  useContext,
  createContext,
  useRef,
  useEffect,
  useState,
} from 'react';
import {
  MAP_CONTAINER_STYLING,
  MAP_DEFAULT_CENTER,
  MAP_FEATURE_OPTIONS,
} from '../../settings/google-maps';
import { MapLayerElement, SwNeBounds, getMapElementsBounds } from './utils';

export type CustomGoogleMapProps = GoogleMapProps & {
  setBoundsToElements?: MapLayerElement[];
};

export const MapTestModeContext = createContext(false);

function CustomGoogleMap(props: CustomGoogleMapProps) {
  const { setBoundsToElements } = props;
  const mapTestMode = useContext(MapTestModeContext);
  const mapOptions = {
    ...MAP_FEATURE_OPTIONS,
    ...(props.options || {}),
  };

  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const boundsRef = useRef<SwNeBounds>();
  useEffect(() => {
    if (mapInstance && setBoundsToElements?.length) {
      const newBounds = getMapElementsBounds(setBoundsToElements);
      if (
        !boundsRef.current ||
        boundsRef.current.sw.lat !== newBounds.sw.lat ||
        boundsRef.current.sw.lng !== newBounds.sw.lng ||
        boundsRef.current.ne.lat !== newBounds.ne.lat ||
        boundsRef.current.ne.lng !== newBounds.ne.lng
      ) {
        boundsRef.current = newBounds;
        mapInstance.fitBounds(
          new google.maps.LatLngBounds(newBounds.sw, newBounds.ne)
        );
      }
    }
  }, [mapInstance, setBoundsToElements]);

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      data-ignore-visual-test-conditional
    >
      <GoogleMap
        mapContainerStyle={MAP_CONTAINER_STYLING}
        center={MAP_DEFAULT_CENTER}
        clickableIcons={false}
        zoom={15}
        {...props}
        onLoad={(map) => {
          setMapInstance(map);
          if (props.onLoad) {
            props.onLoad(map);
          }
        }}
        options={mapOptions}
      >
        {mapTestMode && <MapLayerTestMode />}
        {props.children}
      </GoogleMap>
    </Box>
  );
}

export function MapLayerTestMode() {
  return (
    <Polygon
      paths={[
        { lat: 90, lng: -180 },
        { lat: 90, lng: 0 },
        { lat: 90, lng: 180 },
        { lat: 0, lng: 180 },
        { lat: -90, lng: 180 },
        { lat: -90, lng: 0 },
        { lat: -90, lng: -180 },
        { lat: 0, lng: -180 },
        { lat: 90, lng: -180 },
      ]}
      options={{
        fillColor: '#CCCCCC',
        fillOpacity: 1,
        strokeWeight: 0,
      }}
    />
  );
}

export default memo(CustomGoogleMap);
