import { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment-timezone';
import enMessages from '../lang/en.json';

const DEFAULT_LOCALE = 'en-NZ';

export function ConfiguredIntlProvider({ children }: { children?: ReactNode }) {
  const [selectedLocale, setSelectedLocale] = useState(DEFAULT_LOCALE);
  useEffect(() => {
    if (window.navigator.language === 'en-US') {
      setSelectedLocale('en-US');
      // Note: when adding new moment locale options, check the settings of MomentLocalesPlugin in the webpack file.
      moment.locale('en-US');
    }
  }, []);
  return (
    <IntlProvider
      messages={enMessages}
      locale={selectedLocale}
      defaultLocale={DEFAULT_LOCALE}
      defaultRichTextElements={{
        strong: (chunk) => <strong>{chunk}</strong>,
      }}
    >
      {children}
    </IntlProvider>
  );
}
