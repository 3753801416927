import { useIntl } from 'react-intl';
import { TimePeriodType } from './types';
import { DATE_PRESET_SHORT_COMPLETE } from '../../utils/dateUtils';

export default function useTimePeriod(
  period: TimePeriodType,
  startDate: number,
  endDate: number
): string {
  const { formatMessage, formatDate } = useIntl();
  switch (period) {
    case 'TIME_PERIOD_CUSTOM': {
      return `${formatDate(
        startDate * 1000,
        DATE_PRESET_SHORT_COMPLETE
      )} - ${formatDate(endDate * 1000, DATE_PRESET_SHORT_COMPLETE)}`;
    }
    case 'TIME_PERIOD_LAST_24_HOURS': {
      return formatMessage({
        defaultMessage: 'Last 24 hours',
        id: 'gkjvZz',
        description: 'public view last 24 hours period',
      });
    }
    case 'TIME_PERIOD_LAST_MONTH': {
      return formatMessage({
        defaultMessage: 'Last month',
        id: 'UkB29Z',
        description: 'public view last month period',
      });
    }
    case 'TIME_PERIOD_LAST_WEEK': {
      return formatMessage({
        defaultMessage: 'Last week',
        id: 'EGZRJ2',
        description: 'public view last week period',
      });
    }
    default: {
      return formatMessage({
        defaultMessage: 'Unknown time period',
        id: 'AQOLIU',
        description: 'public view unknown period',
      });
    }
  }
}
