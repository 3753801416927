import {
  Box,
  Flex,
  Heading,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'design-system/atoms/card';
import PublicMap from './PublicMap';
import PublicRouteSelector from './PublicRouteSelector';
import { PathParams, usePublicView } from './usePublicView';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import PublicChart from './PublicChart';
import PublicViewError from './PublicViewError';
import Spinner from '../../components/Spinner';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export default function PublicView() {
  const { linkId } = useParams<PathParams>();
  const {
    viewName,
    viewTimePeriod,
    chartTypes,
    chartsData,
    configError,
    isLoadingConfig,
    isLoadingCharts,
  } = usePublicView();
  const flexLayout = useBreakpointValue({
    md: {
      alignItems: 'flex-start',
    },
  });
  const { track } = useAnalytics();
  const lastTracked = useRef<string>();
  useEffect(() => {
    if (lastTracked.current !== linkId) {
      lastTracked.current = linkId;
      track('Public View Visited', {
        referrer: 'Public View Page',
        linkId,
      });
    }
  }, [linkId, track]);
  if (isLoadingConfig) {
    return (
      <Flex justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }
  return (
    <>
      {configError && !isLoadingConfig ? (
        <PublicViewError />
      ) : (
        <Box width="full" p={8}>
          <Flex mb={4} flexDirection={{ base: 'column-reverse', md: 'row' }}>
            <Flex
              align="baseline"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Heading as="h1" size="lg" mr={2} mb={{ base: 2, md: 0 }}>
                {viewName}
              </Heading>
              <Heading as="h4" fontWeight={600} size="sm">
                {viewTimePeriod}
              </Heading>
            </Flex>
            <Spacer />

            <Box w="150px" h="33px" mb={{ base: 4, md: 0 }}>
              <MoovenLogo />
            </Box>
          </Flex>

          <Box width="full" height={400}>
            <PublicMap />
          </Box>
          <Flex
            width="full"
            mt={8}
            flexDirection={{ base: 'column', md: 'row' }}
            {...flexLayout}
          >
            <Card
              mr={{ base: 0, md: 8 }}
              mb={{ base: 8, md: 0 }}
              minWidth={{ sm: '100%', md: '256px' }}
            >
              <PublicRouteSelector />
            </Card>
            <Box flexGrow={1} overflow="hidden">
              {isLoadingCharts && (
                <Flex justify="center" align="center">
                  <Spinner />
                </Flex>
              )}
              {!isLoadingCharts &&
                chartTypes &&
                chartsData &&
                chartTypes.map((chartType) =>
                  chartsData[chartType] ? (
                    <PublicChart
                      viewChartData={chartsData[chartType]}
                      key={chartType}
                    />
                  ) : null
                )}
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
}
