import { useMemo } from 'react';
import { SiteInsightData } from '../data/useInsightChartData';
import { getCompareColor } from '../utils/colors';
import { InsightChartConfig, InsightChartSeries } from '../utils/insightCharts';

export type SeriesAggregationType =
  | 'none'
  | number // 0 - 100
  | 'max'
  | 'min'
  | 'average'
  | 'count';

export default function useMemoChartDataFromSiteInsight({
  routes,
  hiddenTrackIds,
  colorsByTrackId,
  chartConfig: { mainAxis },
  aggregationType,
  isCompare,
}: {
  routes: SiteInsightData['routes'];
  hiddenTrackIds: string[];
  colorsByTrackId: Record<string, string>;
  chartConfig: InsightChartConfig;
  aggregationType?: SeriesAggregationType;
  isCompare?: boolean;
}) {
  const orderedRoutes = useMemo(
    () =>
      [...(routes ?? [])].sort((routeA, routeB) =>
        routeA.name.localeCompare(routeB.name)
      ),
    [routes]
  );
  const formattedAggregatedData = useMemo(
    () =>
      aggregationType && aggregationType !== 'none'
        ? formatRoutesAggregatedData({ routes: orderedRoutes, aggregationType })
        : undefined,
    [orderedRoutes, aggregationType]
  );
  const { formattedRoutesData, isDataEmpty, isDataHeavy } = useMemo(
    () => processRoutesData(orderedRoutes),
    [orderedRoutes]
  );
  const formattedChartSeries = useMemo(
    () =>
      orderedRoutes.reduce<InsightChartSeries[]>(
        (accSeries, route, routeIndex) => {
          const isRouteVisible = hiddenTrackIds.indexOf(route.trackId) === -1;
          const routeSeriesProps = {
            visible: isRouteVisible,
            color: colorsByTrackId[route.trackId],
            animation: isDataHeavy ? false : undefined,
            name: route.name,
          };
          const isAggregation = aggregationType && aggregationType !== 'none';
          accSeries.push({
            ...routeSeriesProps,
            type: mainAxis.type,
            data: formattedRoutesData[routeIndex],
            showInLegend: isRouteVisible,
            keys: ['x', 'y', 'compareValue'],
            zIndex: 300,
            yAxis: 0,
            // This gives a nice experience for aggregated tooltip, but triggers a bug in highcharts
            // where the series will pick up the wrong colors when aggregation type changes. Maybe we
            // can fix it by redrawing the chart when this configuration changes.
            // ...(isAggregation ? {
            // custom: { hideFromTooltip: true },
            // enableMouseTracking: false,
            // states: {
            //   inactive: {
            //     opacity: 1
            //   }
            // }
            // } : {})
          });
          if (isAggregation && formattedAggregatedData) {
            accSeries.push({
              ...routeSeriesProps,
              type: 'line',
              data: formattedAggregatedData[routeIndex],
              showInLegend: false,
              keys: ['x', 'y'], // todo: check if needs comparison value
              zIndex: 400,
              yAxis: 0,
            });
          }
          if (isCompare) {
            accSeries.push({
              ...routeSeriesProps,
              type: mainAxis.type,
              data: formattedRoutesData[routeIndex],
              showInLegend: false,
              keys: ['x', '_ignore_key', 'y'],
              color: getCompareColor(routeSeriesProps.color),
              custom: { hideFromTooltip: true },
              zIndex: 200,
              yAxis: 0,
            });
          }
          return accSeries;
        },
        []
      ),
    [
      orderedRoutes,
      formattedRoutesData,
      hiddenTrackIds,
      colorsByTrackId,
      mainAxis,
      isCompare,
      isDataHeavy,
      aggregationType,
      formattedAggregatedData,
    ]
  );

  return { formattedChartSeries, isDataEmpty, isDataHeavy };
}

function processRoutesData(routes: SiteInsightData['routes']) {
  let dataPointCount = 0;
  const formattedRoutesData: [
    number,
    number | undefined,
    number | undefined,
  ][][] = [];
  routes.forEach((route) => {
    const routeData: [number, number | undefined, number | undefined][] = [];
    dataPointCount += route.items.length;
    route.items.forEach((routeItem, itemIndex) => {
      routeData.push([
        routeItem.date,
        routeItem.value ?? undefined,
        route.compareItems?.[itemIndex]?.value ?? undefined,
      ]);
    });
    formattedRoutesData.push(routeData);
  });
  return {
    formattedRoutesData,
    isDataEmpty: dataPointCount === 0,
    isDataHeavy: dataPointCount > 10000,
  };
}

function formatRoutesAggregatedData({
  routes,
  aggregationType,
}: {
  routes: SiteInsightData['routes'];
  aggregationType: SeriesAggregationType;
}) {
  return routes.map((route) =>
    aggregatePoints({
      points: route.items,
      aggregationType,
    })
  );
}

function aggregatePoints({
  points,
  aggregationType,
  bucketSizeMinutes = 15,
}: {
  points: { date: number; value: number | null }[];
  aggregationType: SeriesAggregationType;
  bucketSizeMinutes?: number;
}): [number, number?][] {
  const bucket = 1000 * 60 * bucketSizeMinutes; // TODO: adjust bucket to middle of window
  const groupedPoints = points.reduce<Record<number, number[]>>(
    (acc, { date, value }) => {
      const pointKey = date - (date % bucket);
      if (typeof value === 'number') {
        acc[pointKey] = [...(acc[pointKey] ?? []), value];
      }
      return acc;
    },
    {}
  );

  return Object.entries(groupedPoints)
    .map<[number, number?]>(([date, values]) => {
      if (aggregationType === 'count') {
        return [Number(date), values.length];
      }
      if (aggregationType === 'average') {
        return [
          Number(date),
          values.reduce((acc, value) => acc + value, 0) / values.length,
        ];
      }
      if (aggregationType === 'max') {
        return [Number(date), Math.max(...values)];
      }
      if (aggregationType === 'min') {
        return [Number(date), Math.min(...values)];
      }
      if (
        typeof aggregationType === 'number' &&
        Number.isFinite(aggregationType)
      ) {
        const sortedValues = values.sort();
        const percentileIndex =
          Math.ceil((aggregationType / 100) * sortedValues.length) - 1;
        return [Number(date), sortedValues[percentileIndex]];
      }
      return [Number(date), undefined];
    })
    .sort((pointA, pointB) => pointA[0] - pointB[0]);
}
