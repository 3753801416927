export default {
  defaultProps: {
    colorScheme: 'green',
    focusBorderColor: 'green.500',
    errorBorderColor: 'red.500',
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'md',
      },
    },
  },
};
