import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    fontWeight: 'normal',
    verticalAlign: 'baseline',
    color: `${c}.700`,
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none',
      },
    },
    _active: {
      color: `${c}.900`,
    },
  };
};

export default {
  variants: {
    link: variantLink,
  },
};
