import rg4js from 'raygun4js';

type CustomData = Record<any, any> | any[];
enum Severity {
  CRITICAL = 'critical',
  HANDLED = 'handled',
  UNKNOWN = 'unknown',
  LOG = 'log',
}
// critical: errors that require immediate attention
// handled: errors that won't crash the application but could indicate a problem
// log: for debugging purposes or providing additional context on external errors (such as server calls)
// unknown: errors that don't fit, or could fit multiple other categories

export const errorReport = {
  critical: reportErrorWithSeverityTag(Severity.CRITICAL),
  handled: reportErrorWithSeverityTag(Severity.HANDLED),
  log: reportErrorWithSeverityTag(Severity.LOG),
  unknown: reportErrorWithSeverityTag(Severity.UNKNOWN),
};

function reportErrorWithSeverityTag(tag: Severity) {
  return (e: any, customData?: CustomData) => {
    try {
      rg4js('send', {
        error: e,
        customData,
        tags: [tag],
      });
      // eslint-disable-next-line no-console
      console.log('error', e, customData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Raygun error', err, e, customData);
    }
  };
}
