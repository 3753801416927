import { IntlFormatters } from 'react-intl';
import { ColorString, TooltipFormatterContextObject } from 'highcharts';
import { Fragment } from 'react';
import styles from './Tooltip.module.scss';
import ArrowIcon from './ArrowIcon';
import { dateFormatter } from '../../utils/dateUtils';

export default function Tooltip({
  timeZone,
  points,
  dateFormat,
  formatValue,
  compareDate,
  dataStartTime,
  formatMessage,
}: {
  timeZone: string;
  points: Array<TooltipFormatterContextObject>;
  dateFormat?: string;
  formatValue?: (value: number) => string;
  compareDate?: number;
  dataStartTime?: number;
  formatMessage: IntlFormatters['formatMessage'];
}) {
  const timeOfSelectedPoint = (points[0].x as number) / 1000;
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {dateFormatter(timeOfSelectedPoint, timeZone, dateFormat || 'llll')}
      </div>
      {compareDate && (
        <div className={styles.subTitle}>
          <span className={styles.subTitleLabel}>compared to: </span>
          <span className={styles.subTitleValue}>
            {
              // TODO: adjust for inactive sites dates offset
              dateFormatter(
                compareDate / 1000 +
                  (timeOfSelectedPoint - (dataStartTime || 0) / 1000),
                timeZone,
                'llll'
              )
            }
          </span>
        </div>
      )}
      {points.map(
        (p, pIndex) =>
          !p.point.series.options.custom?.hideFromTooltip &&
          typeof p.y === 'number' && (
            <Fragment key={typeof p.color === 'string' ? p.color : pIndex}>
              <TooltipItem
                color={p.color as ColorString}
                itemName={p.series.name || ''}
                value={p.y!}
                formatValue={formatValue}
              />
              {typeof (p.point as any).compareValue === 'number' && (
                <ComparedTooltipItem
                  changeValue={p.y - (p.point as any).compareValue}
                  formatValue={formatValue}
                  formatMessage={formatMessage}
                />
              )}
            </Fragment>
          )
      )}
    </div>
  );
}

function TooltipItem({
  color,
  itemName,
  value,
  formatValue,
}: {
  color: ColorString;
  itemName: string;
  value: number;
  formatValue?: (value: number) => string;
}) {
  return (
    <div className={styles.item}>
      <div className={styles.itemName}>
        <div className={styles.colorTag} style={{ background: `${color}` }} />
        <span className={styles.itemLabel}>
          {limitTooltipItemName(itemName)}
        </span>
      </div>
      <span className={styles.itemValue}>
        {formatValue ? formatValue(value) : value}
      </span>
    </div>
  );
}

function ComparedTooltipItem({
  changeValue,
  formatValue,
  formatMessage,
}: {
  changeValue: number;
  formatValue?: (value: number) => string;
  formatMessage: IntlFormatters['formatMessage'];
}) {
  return (
    <div className={styles.comparedItem}>
      <span className={styles.comparedItemLabel}>
        {formatMessage({
          defaultMessage: 'Difference',
          id: 'pZ4Ukb',
          description: "Chart tooltip, comparison value's diff label.",
        })}
      </span>
      <div className={styles.comparedItemValueGroup}>
        {changeValue === 0 ? null : (
          <ArrowIcon direction={changeValue > 0 ? 'up' : 'down'} />
        )}
        <span className={styles.comparedItemValue}>
          {formatValue
            ? formatValue(Math.abs(changeValue))
            : Math.abs(changeValue)}
        </span>
      </div>
    </div>
  );
}

function limitTooltipItemName(routeName: string): string {
  if (routeName.length > 30) {
    return `${routeName.substring(0, 29)}...`;
  }
  return routeName;
}
