/* eslint-disable func-names */
// HACK: Monkey-patching local storage methods
// to notify disparate branches of React tree
// when storage changes.

export const STORAGE_CHANGE_EV = 'storageChanged';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (...args) {
  const ev = new Event(STORAGE_CHANGE_EV);
  originalSetItem.apply(this, args);
  setTimeout(() => {
    document.dispatchEvent(ev);
  }, 50);
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function (...args) {
  const ev = new Event(STORAGE_CHANGE_EV);
  originalRemoveItem.apply(this, args);
  setTimeout(() => {
    document.dispatchEvent(ev);
  }, 50);
};

const originalClear = localStorage.clear;
localStorage.clear = function () {
  const ev = new Event(STORAGE_CHANGE_EV);
  const lastProjectSelected = localStorage.getItem('last-project-selected');
  originalClear.apply(this);
  if (lastProjectSelected) {
    localStorage.setItem('last-project-selected', lastProjectSelected);
  }
  setTimeout(() => {
    document.dispatchEvent(ev);
  }, 50);
};
