import { ReactNode } from 'react';
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import CardThemeConfig from '../../theme/Card';

export type CardVariant = keyof (typeof CardThemeConfig)['variants'];

export interface CardProps extends BoxProps {
  variant?: CardVariant;
  colorMode?: 'light' | 'dark';
  children: ReactNode;
}

export default function Card({
  variant,
  colorMode = 'light',
  children,
  ...rest
}: CardProps) {
  const styles = useStyleConfig('Card', { variant });
  return (
    <Box
      backgroundColor={colorMode === 'light' ? 'white' : 'gray.100'}
      __css={styles}
      {...rest}
    >
      {children}
    </Box>
  );
}
