import { wktToPointArray } from '@lib/gis';
import LatLngLiteral = google.maps.LatLngLiteral;

type MapLayerPoint = LatLngLiteral;
type MapLayerWithPath = { path: LatLngLiteral[] };
type MapLayerWithWkt = { wkt: string };
export type MapLayerElement =
  | MapLayerPoint
  | MapLayerWithPath
  | MapLayerWithWkt;
export interface SwNeBounds {
  sw: LatLngLiteral;
  ne: LatLngLiteral;
}
export function getMapElementsBounds(
  elements: MapLayerElement[],
  minDiameter = 0.005
): SwNeBounds {
  const latLngs = elements.flatMap((element) => {
    if ('wkt' in element) {
      return wktToPointArray(element.wkt);
    }
    if ('path' in element) {
      return element.path;
    }
    return element;
  });
  const bounds = getBounds(latLngs);
  const latDistance = bounds.ne.lat - bounds.sw.lat;
  const lngDistance = bounds.ne.lng - bounds.sw.lng;
  if (latDistance > minDiameter || lngDistance > minDiameter) {
    return bounds;
  }
  const latAdjustment = Math.max(0, (minDiameter - latDistance) / 2);
  const lngAdjustment = Math.max(0, (minDiameter - lngDistance) / 2);
  return {
    sw: {
      lat: bounds.sw.lat - latAdjustment,
      lng: bounds.sw.lng - lngAdjustment,
    },
    ne: {
      lat: bounds.ne.lat + latAdjustment,
      lng: bounds.ne.lng + lngAdjustment,
    },
  };
}
function getBounds(latlngs: LatLngLiteral[]): SwNeBounds {
  const bounds = {
    sw: { lat: 90, lng: 180 },
    ne: { lat: -90, lng: -180 },
  };
  latlngs.forEach((latlng) => {
    if (latlng.lat < bounds.sw.lat) {
      bounds.sw.lat = latlng.lat;
    }
    if (latlng.lng < bounds.sw.lng) {
      bounds.sw.lng = latlng.lng;
    }
    if (latlng.lat > bounds.ne.lat) {
      bounds.ne.lat = latlng.lat;
    }
    if (latlng.lng > bounds.ne.lng) {
      bounds.ne.lng = latlng.lng;
    }
  });
  return bounds;
}
